<div
  gdAreas="filters | progress | list | pager | editor"
  gdRows="80px 64px 2fr 40px 1fr"
  gdColumns="1fr"
  gdGap="8px"
>
  <div>
    <!-- filters -->
    <div gdArea="filters">
      <auth-jwt-user-filter></auth-jwt-user-filter>
    </div>

    <!-- progress -->
    <div *ngIf="paginator.isLoading$ | async" gdArea="progress">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <!-- list -->
    <div gdArea="list" *ngIf="pagination$ | async as pagination">
      <table>
        <thead>
          <td></td>
          <td></td>
          <td></td>
          <th>name</th>
          <th>first</th>
          <th>last</th>
          <th>email</th>
          <th>roles</th>
          <th>lock end</th>
        </thead>
        <tbody>
          <tr *ngFor="let user of pagination.data">
            <td class="command">
              <button
                mat-icon-button
                type="button"
                matTooltip="Edit {{ user.userName }}"
                color="primary"
                (click)="setActiveUser(user)"
              >
                <mat-icon>mode_edit</mat-icon>
              </button>
            </td>
            <td class="command">
              <button
                mat-icon-button
                type="button"
                matTooltip="Delete {{ user.userName }}"
                color="warn"
                (click)="deleteUser(user)"
              >
                <mat-icon>remove_circle</mat-icon>
              </button>
            </td>
            <td>
              <img
                [src]="getGravatarUrl(user.email, 32)"
                [alt]="user.userName"
              />
            </td>
            <td>{{ user.userName }}</td>
            <td>{{ user.firstName }}</td>
            <td>{{ user.lastName }}</td>
            <td>
              <a [href]="'mailto:' + user.email">{{ user.email }}</a>
            </td>
            <td>{{ user.roles.join(" ") }}</td>
            <td>{{ user.lockoutEnd }}</td>
          </tr>
        </tbody>
      </table>
      <!-- pagination -->
      <mat-paginator
        gdArea="pager"
        gdAlignColumns="center"
        gdAlignRows="start"
        [length]="pagination.total"
        [pageSize]="pageSize.value"
        [pageSizeOptions]="[20, 50, 75, 100]"
        [pageIndex]="pagination.currentPage - 1"
        [showFirstLastButtons]="true"
        (page)="pageChange($event)"
      ></mat-paginator>
    </div>
  </div>

  <!-- editor -->
  <div gdArea="editor" *ngIf="active$ | async as active">
    <fieldset>
      <legend>{{ active.userName }}</legend>
      <auth-jwt-user-editor
        [user]="active"
        (userChange)="saveActiveUser($event)"
        (editorClose)="resetActiveUser()"
      ></auth-jwt-user-editor>
    </fieldset>
  </div>
</div>
