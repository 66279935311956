<form [formGroup]="form">
  <div style="display: flex; gap: 6px">
    <!-- tag (bound) -->
    <mat-form-field
      *ngIf="assTagEntries?.length"
      style="width: 8em; flex: 0 0 auto"
    >
      <mat-select [formControl]="tag" placeholder="tag">
        <mat-option *ngFor="let e of assTagEntries" [value]="e.id">{{
          e.value
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- tag (free) -->
    <mat-form-field
      *ngIf="!assTagEntries?.length"
      style="width: 8em; flex: 0 0 auto"
    >
      <input matInput [formControl]="tag" placeholder="tag" />
      <mat-error *ngIf="tag.errors?.maxLength && (tag.dirty || tag.touched)"
        >tag too long</mat-error
      >
    </mat-form-field>

    <!-- rank -->
    <mat-form-field style="width: 3em; flex: 0 0 auto">
      <input
        matInput
        [formControl]="rank"
        placeholder="rank"
        type="number"
        min="0"
      />
    </mat-form-field>

    <!-- note -->
    <mat-form-field style="width: 100%; max-width: 600px; flex: 1 0 auto">
      <input matInput [formControl]="note" placeholder="note" />
      <mat-error *ngIf="note.errors?.maxLength && (note.dirty || note.touched)"
        >note too long</mat-error
      >
    </mat-form-field>
  </div>
  <div>
    <!-- references -->
    <mat-expansion-panel>
      <mat-expansion-panel-header
        >assertion references</mat-expansion-panel-header
      >
      <cadmus-refs-doc-references
        [typeEntries]="refTypeEntries"
        [tagEntries]="refTagEntries"
        [references]="initialReferences"
        (referencesChange)="onReferencesChange($event)"
      ></cadmus-refs-doc-references>
    </mat-expansion-panel>
  </div>
</form>
