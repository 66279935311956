<form [formGroup]="form">
  <div formArrayName="idsArr">
    <div>
      <button
        type="button"
        mat-stroked-button
        color="primary"
        (click)="addId()"
        matTooltip="Add a new ID"
      >
        <mat-icon>add_circle</mat-icon> add ID
      </button>
    </div>
    <div
      *ngFor="
        let item of idsArr.controls;
        let i = index;
        let first = first;
        let last = last
      "
    >
      <!-- child form -->
      <div [formGroupName]="i">
        <!-- child actions -->
        {{ i + 1 }}.
        <button
          mat-icon-button
          type="button"
          matTooltip="Remove this ID"
          color="warn"
          (click)="removeId(i)"
        >
          <mat-icon>remove_circle</mat-icon>
        </button>
        <button
          [disabled]="first"
          mat-icon-button
          type="button"
          matTooltip="Move ID up"
          (click)="moveIdUp(i)"
        >
          <mat-icon>arrow_upward</mat-icon>
        </button>
        <button
          [disabled]="last"
          mat-icon-button
          type="button"
          matTooltip="Move ID down"
          (click)="moveIdDown(i)"
        >
          <mat-icon>arrow_downward</mat-icon>
        </button>

        <!-- child controls -->
        <!-- value -->
        <mat-form-field>
          <input
            #id
            autofocus
            matInput
            formControlName="value"
            placeholder="external ID"
          />
          <mat-error
            *ngIf="
              $any(item)['controls'].value?.hasError('required') &&
              ($any(item)['controls'].value.dirty ||
                $any(item)['controls'].value.touched)
            "
            >ID required
          </mat-error>
          <mat-error
            *ngIf="
              $any(item)['controls'].value?.hasError('max-length') &&
              ($any(item)['controls'].value.dirty ||
                $any(item)['controls'].value.touched)
            "
            >ID too long
          </mat-error>
        </mat-form-field>

        &nbsp;
        <!-- scope (bound) -->
        <ng-container *ngIf="scopeEntries">
          <mat-form-field style="width: 8em">
            <mat-select formControlName="scope" placeholder="scope">
              <mat-option *ngFor="let e of scopeEntries" [value]="e.id">
                {{ e.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <!-- scope (free) -->
        <ng-container *ngIf="!scopeEntries">
          <mat-form-field style="width: 8em">
            <input matInput formControlName="scope" placeholder="scope" />
            <mat-error
              *ngIf="
                $any(item)['controls'].scope?.hasError('max-length') &&
                ($any(item)['controls'].scope.dirty ||
                  $any(item)['controls'].scope.touched)
              "
              >scope too long
            </mat-error>
          </mat-form-field>
        </ng-container>

        &nbsp;
        <!-- tag (bound) -->
        <ng-container *ngIf="tagEntries">
          <mat-form-field style="width: 8em">
            <mat-select formControlName="tag" placeholder="tag">
              <mat-option *ngFor="let e of tagEntries" [value]="e.id">
                {{ e.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>

        <!-- tag (free) -->
        <ng-container *ngIf="!tagEntries">
          <mat-form-field style="width: 8em">
            <input matInput formControlName="tag" placeholder="tag" />
            <mat-error
              *ngIf="
                $any(item)['controls'].tag?.hasError('max-length') &&
                ($any(item)['controls'].tag.dirty ||
                  $any(item)['controls'].tag.touched)
              "
              >tag too long
            </mat-error>
          </mat-form-field>
        </ng-container>

        <!-- assertion -->
        &nbsp;
        <button
          type="button"
          color="primary"
          mat-icon-button
          matTooltip="Edit assertion"
          (click)="editAssertion(i)"
        >
          <mat-icon>feedback</mat-icon>
        </button>
      </div>
    </div>
    <!-- assertion -->
    <mat-expansion-panel
      *ngIf="idsArr?.length"
      [disabled]="!assertionNr"
      [(expanded)]="assEdOpen"
    >
      <mat-expansion-panel-header
        >#{{ assertionNr }} assertion</mat-expansion-panel-header
      >
      <cadmus-refs-assertion
        [assertion]="initialAssertion"
        [assTagEntries]="assTagEntries"
        [refTagEntries]="refTagEntries"
        [refTypeEntries]="refTypeEntries"
        (assertionChange)="onAssertionChange($event)"
      ></cadmus-refs-assertion>
      <button
        color="primary"
        type="button"
        mat-icon-button
        (click)="saveAssertion()"
      >
        <mat-icon>check_circle</mat-icon> save assertion
      </button>
    </mat-expansion-panel>
  </div>
</form>
