<form [formGroup]="form!" (submit)="save()">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>picture_in_picture</mat-icon>
      </div>
      <mat-card-title>Comment</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group>
        <mat-tab label="comment">
          <!-- tag (bound) -->
          <div>
            <mat-form-field *ngIf="comTagEntries?.length" style="width: 8em">
              <mat-select [formControl]="tag" placeholder="tag">
                <mat-option *ngFor="let e of comTagEntries" [value]="e.id">{{
                  e.value
                }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- tag (free) -->
            <mat-form-field *ngIf="!comTagEntries?.length" style="width: 8em">
              <input matInput [formControl]="tag" placeholder="tag" />
              <mat-error
                *ngIf="tag.hasError('maxLength') && (tag.dirty || tag.touched)"
                >tag too long</mat-error
              >
            </mat-form-field>
          </div>
          <!-- text -->
          <div>
            <ngx-monaco-editor
              [options]="editorOptions"
              formControlName="text"
            ></ngx-monaco-editor>
            <mat-error
              *ngIf="text.hasError('required') && (text.touched || text.dirty)"
              >text required</mat-error
            >
          </div>
          <hr />
          <mat-expansion-panel>
            <mat-expansion-panel-header>preview</mat-expansion-panel-header>
            <markdown [data]="text.value || undefined"></markdown>
          </mat-expansion-panel>
        </mat-tab>

        <mat-tab label="references">
          <h3>references</h3>
          <!-- references -->
          <div>
            <cadmus-refs-doc-references
              [references]="initialRefs"
              [tagEntries]="docTagEntries"
              [typeEntries]="docTypeEntries"
              (referencesChange)="onReferencesChange($event)"
            >
            </cadmus-refs-doc-references>
          </div>

          <!-- ids -->
          <br />
          <h3>external IDs</h3>
          <div>
            <cadmus-refs-asserted-ids
              [ids]="initialIds"
              [scopeEntries]="idScopeEntries"
              [tagEntries]="idTagEntries"
              [assTagEntries]="assTagEntries"
              [refTagEntries]="docTagEntries"
              [refTypeEntries]="docTypeEntries"
              (idsChange)="onIdsChange($event)"
            >
            </cadmus-refs-asserted-ids>
          </div>
        </mat-tab>

        <!-- categories -->
        <mat-tab label="categories" *ngIf="catEntries?.length">
          <cadmus-thesaurus-tree
            [entries]="catEntries"
            rootLabel="categories"
            [renderLabel]="renderLabel"
            (entryChange)="onCategoryChange($event)"
          ></cadmus-thesaurus-tree>
          <hr />
          <div *ngIf="categories.value?.length">
            <table>
              <tbody>
                <tr *ngFor="let entry of categories.value; let i = index">
                  <td>
                    <button
                      type="button"
                      mat-icon-button
                      color="warn"
                      type="button"
                      matTooltip="Remove this category"
                      (click)="removeCategory(i)"
                    >
                      <mat-icon>remove_circle</mat-icon>
                    </button>
                  </td>
                  <td [matTooltip]="entry.id">{{ entry.value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-tab>

        <!-- keywords -->
        <mat-tab label="keywords">
          <div formArrayName="keywords">
            <div>
              <button
                type="button"
                mat-icon-button
                color="primary"
                (click)="addKeyword()"
              >
                <mat-icon>add_circle</mat-icon>
                add keyword
              </button>
            </div>
            <div
              *ngFor="
                let item of keywords.controls;
                let i = index;
                let first = first;
                let last = last
              "
            >
              <!-- child form -->
              <div [formGroupName]="i">
                <!-- child actions -->
                {{ i + 1 }}.
                <button
                  mat-icon-button
                  type="button"
                  matTooltip="Remove this Keyword"
                  color="warn"
                  (click)="removeKeyword(i)"
                >
                  <mat-icon>remove_circle</mat-icon>
                </button>
                <button
                  [disabled]="first"
                  mat-icon-button
                  type="button"
                  matTooltip="Move Keyword up"
                  (click)="moveKeywordUp(i)"
                >
                  <mat-icon>arrow_upward</mat-icon>
                </button>
                <button
                  [disabled]="last"
                  mat-icon-button
                  type="button"
                  matTooltip="Move Keyword down"
                  (click)="moveKeywordDown(i)"
                >
                  <mat-icon>arrow_downward</mat-icon>
                </button>

                <!-- child controls -->
                <!-- indexId (bound) -->
                <mat-form-field *ngIf="idxEntries?.length" style="width: 8em">
                  <mat-select formControlName="indexId" placeholder="index">
                    <mat-option *ngFor="let e of idxEntries" [value]="e.id">{{
                      e.value
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- indexId (free) -->
                <mat-form-field *ngIf="!idxEntries?.length" style="width: 8em">
                  <input
                    matInput
                    formControlName="indexId"
                    placeholder="index"
                  />
                  <mat-error
                    *ngIf="
                      $any(item)['controls'].indexId.errors?.maxLength &&
                      ($any(item)['controls'].indexId.dirty ||
                        $any(item)['controls'].indexId.touched)
                    "
                    >ID too long</mat-error
                  >
                </mat-form-field>
                &nbsp;
                <!-- tag (bound) -->
                <mat-form-field
                  *ngIf="keyTagEntries?.length"
                  style="width: 8em"
                >
                  <mat-select formControlName="tag" placeholder="tag">
                    <mat-option
                      *ngFor="let e of keyTagEntries"
                      [value]="e.id"
                      >{{ e.value }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <!-- tag (free) -->
                <mat-form-field
                  *ngIf="!keyTagEntries?.length"
                  style="width: 8em"
                >
                  <input matInput formControlName="tag" placeholder="tag" />
                  <mat-error
                    *ngIf="
                      $any(item)['controls'].tag.errors?.maxLength &&
                      ($any(item)['controls'].tag.dirty ||
                        $any(item)['controls'].tag.touched)
                    "
                    >tag too long</mat-error
                  >
                </mat-form-field>

                <div class="ind-long-text">
                  <!-- language (bound) -->
                  <mat-form-field
                    *ngIf="langEntries?.length"
                    style="width: 8em"
                  >
                    <mat-select
                      formControlName="language"
                      placeholder="language"
                    >
                      <mat-option
                        *ngFor="let e of langEntries"
                        [value]="e.id"
                        >{{ e.value }}</mat-option
                      >
                    </mat-select>
                    <mat-error
                      *ngIf="
                        $any(item)['controls'].language.errors?.required &&
                        ($any(item)['controls'].language.dirty ||
                          $any(item)['controls'].language.touched)
                      "
                      >language required</mat-error
                    >
                  </mat-form-field>

                  <!-- language (free) -->
                  <mat-form-field
                    *ngIf="!langEntries?.length"
                    style="width: 8em"
                  >
                    <input
                      matInput
                      formControlName="language"
                      placeholder="language"
                    />
                    <mat-error
                      *ngIf="
                        $any(item)['controls'].language.errors?.required &&
                        ($any(item)['controls'].language.dirty ||
                          $any(item)['controls'].language.touched)
                      "
                      >language required</mat-error
                    >
                    <mat-error
                      *ngIf="
                        $any(item)['controls'].language.errors?.maxLength &&
                        ($any(item)['controls'].language.dirty ||
                          $any(item)['controls'].language.touched)
                      "
                      >language too long</mat-error
                    >
                  </mat-form-field>

                  &nbsp;
                  <!-- value -->
                  <mat-form-field>
                    <input
                      matInput
                      formControlName="value"
                      placeholder="value"
                    />
                    <mat-error
                      *ngIf="
                        $any(item)['controls'].value.errors?.required &&
                        ($any(item)['controls'].value.dirty ||
                          $any(item)['controls'].value.touched)
                      "
                      >value required</mat-error
                    >
                    <mat-error
                      *ngIf="
                        $any(item)['controls'].value.errors?.maxLength &&
                        ($any(item)['controls'].value.dirty ||
                          $any(item)['controls'].value.touched)
                      "
                      >value too long</mat-error
                    >
                  </mat-form-field>
                </div>
                <div>
                  <!-- note -->
                  <mat-form-field class="ind-long-text">
                    <textarea
                      matInput
                      formControlName="note"
                      placeholder="note"
                    ></textarea>
                    <mat-error
                      *ngIf="
                        $any(item)['controls'].note.errors?.maxLength &&
                        ($any(item)['controls'].note.dirty ||
                          $any(item)['controls'].note.touched)
                      "
                      >note too long</mat-error
                    >
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
    <mat-card-actions>
      <cadmus-close-save-buttons
        [form]="form"
        [noSave]="userLevel < 2"
        (closeRequest)="close()"
      ></cadmus-close-save-buttons>
    </mat-card-actions>
  </mat-card>
</form>
