<form [formGroup]="form!" (submit)="save()">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>picture_in_picture</mat-icon>
      </div>
      <mat-card-title>Historical Date Part</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <cadmus-refs-historical-date
        [date]="date"
        (dateChange)="onDateChange($event)"
      ></cadmus-refs-historical-date>
      <!-- references -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>references</mat-panel-title>
        </mat-expansion-panel-header>
        <cadmus-refs-doc-references
          [references]="initialRefs"
          [typeEntries]="typeEntries"
          [tagEntries]="tagEntries"
          (referencesChange)="onReferencesChange($event)"
        ></cadmus-refs-doc-references>
      </mat-expansion-panel>
    </mat-card-content>
    <mat-card-actions>
      <cadmus-close-save-buttons
        [form]="form"
        [noSave]="userLevel < 2"
        (closeRequest)="close()"
      ></cadmus-close-save-buttons>
    </mat-card-actions>
  </mat-card>
</form>
