import { Injectable } from '@angular/core';
import { User } from '@myrmidon/auth-jwt-login';
import {
  AuthJwtAccountService,
  UserFilter,
} from '../../services/auth-jwt-account.service';
import { UsersStore } from './users.store';

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(
    private _store: UsersStore,
    private _accountService: AuthJwtAccountService
  ) {}

  /**
   * Update the filter in the store.
   * @param filter The filter.
   */
  public updateFilter(filter: UserFilter): void {
    this._store.update({
      filter: filter,
    });
  }

  public setActive(name: string | null): void {
    this._store.setActive(name);
  }

  public updateActive(user: User): Promise<boolean> {
    const promise: Promise<boolean> = new Promise((resolve, reject) => {
      this._store.setLoading(true);

      this._accountService.updateUser(user).subscribe(
        (_) => {
          this._store.setLoading(false);
          this._store.setError(null);
          this._store.updateActive((u) => {
            return { ...user };
          });
          resolve(true);
        },
        (error) => {
          console.error(error);
          this._store.setLoading(false);
          this._store.setError('Error updating user');
          resolve(false);
        }
      );
    });
    return promise;
  }

  public deleteUser(name: string): Promise<boolean> {
    const promise: Promise<boolean> = new Promise((resolve, reject) => {
      this._store.setLoading(true);

      this._accountService.deleteUser(name).subscribe(
        (_) => {
          this._store.setLoading(false);
          this._store.setError(null);
          this._store.remove(name);
          resolve(true);
        },
        (error) => {
          console.error(error);
          this._store.setLoading(false);
          this._store.setError('Error deleting user');
          reject(error);
        }
      );
    });
    return promise;
  }
}
