import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserFilter } from '../../services/auth-jwt-account.service';
import { UsersQuery } from '../state/users.query';
import { UsersService } from '../state/users.service';

@Component({
  selector: 'auth-jwt-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.css'],
})
export class UserFilterComponent implements OnInit {
  @Input()
  public disabled: boolean | undefined;

  public filter$: Observable<UserFilter>;

  public name: UntypedFormControl;
  public form: UntypedFormGroup;

  constructor(
    formBuilder: UntypedFormBuilder,
    query: UsersQuery,
    private _corporaService: UsersService
  ) {
    this.filter$ = query.selectFilter();

    // form
    this.name = formBuilder.control(null);
    this.form = formBuilder.group({
      name: this.name,
    });
  }

  ngOnInit(): void {
    this.filter$.subscribe((f) => {
      this.updateForm(f);
    });
  }

  private updateForm(filter: UserFilter): void {
    this.name.setValue(filter.name);
    this.form.markAsPristine();
  }

  public reset(): void {
    this.form.reset();
    this.apply();
  }

  private getFilter(): UserFilter {
    return {
      pageNumber: 1, // not used
      pageSize: 20, // not used
      name: this.name.value?.trim(),
    };
  }

  public apply(): void {
    if (this.form.invalid) {
      return;
    }
    const filter = this.getFilter();

    // update filter in state
    this._corporaService.updateFilter(filter);
  }
}
