<form [formGroup]="form" (submit)="save()">
  <!-- email -->
  <div>
    <mat-form-field>
      <input type="text" matInput [formControl]="email" placeholder="email" />
      <mat-error
        *ngIf="email.hasError('required') && (email.dirty || email.touched)"
      >
        email address required
      </mat-error>
      <mat-error
        *ngIf="email.hasError('pattern') && (email.dirty || email.touched)"
      >
        invalid email address
      </mat-error>
    </mat-form-field>
  </div>

  <!-- emailConfirmed -->
  <div>
    <mat-checkbox [formControl]="emailConfirmed"
      >email address confirmed</mat-checkbox
    >
  </div>

  <!-- lockoutEnabled -->
  <div>
    <mat-checkbox [formControl]="lockoutEnabled"
      >lockout enabled</mat-checkbox
    >
    &nbsp;
    <button
      mat-stroked-button
      (click)="endLockout()"
      [disabled]="unlocked"
      matTooltip="Unlock this user if locked"
    >
      unlock
    </button>
  </div>

  <!-- firstName -->
  <div>
    <mat-form-field>
      <input
        type="text"
        matInput
        [formControl]="firstName"
        placeholder="first name"
      />
      <mat-error
        *ngIf="
          firstName.hasError('required') &&
          (firstName.dirty || firstName.touched)
        "
      >
        first name required
      </mat-error>
      <mat-error
        *ngIf="
          firstName.hasError('maxlength') &&
          (firstName.dirty || firstName.touched)
        "
      >
        first name too long
      </mat-error>
    </mat-form-field>
  </div>

  <!-- lastName -->
  <div>
    <mat-form-field>
      <input
        type="text"
        matInput
        [formControl]="lastName"
        placeholder="last name"
      />
      <mat-error
        *ngIf="
          lastName.hasError('required') && (lastName.dirty || lastName.touched)
        "
      >
        last name required
      </mat-error>
      <mat-error
        *ngIf="
          lastName.hasError('maxlength') && (lastName.dirty || lastName.touched)
        "
      >
        last name too long
      </mat-error>
    </mat-form-field>
  </div>

  <!-- roles -->
  <div>
    <mat-form-field>
      <input
        type="text"
        matInput
        [formControl]="roles"
        placeholder="roles (separated by space)"
      />
      <mat-error
        *ngIf="roles.hasError('maxlength') && (roles.dirty || roles.touched)"
      >
        too long
      </mat-error>
    </mat-form-field>
  </div>

  <!-- buttons -->
  <br />
  <div>
    <button mat-icon-button color="warn" matTooltip="Close" (click)="close()">
      <mat-icon>cancel</mat-icon>
    </button>
    &nbsp;
    <button
      type="submit"
      mat-button
      color="primary"
      [disabled]="form.invalid"
      matTooltip="Save user data"
    >
      <mat-icon>check_circle</mat-icon> save
    </button>
  </div>
</form>
