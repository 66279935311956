<form [formGroup]="form" (ngSubmit)="requestLogin()">
  <div>
    <mat-form-field appearance="fill">
      <input
        type="text"
        matInput
        placeholder="username"
        formControlName="name"
      />
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="fill">
      <input
        [type]="hide ? 'password' : 'text'"
        matInput
        placeholder="password"
        formControlName="password"
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        aria-label="Hide password"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div *ngIf="errorMessage" class="loginError">
    {{ errorMessage }}
  </div>

  <div>
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="validating"
    ></mat-progress-bar>
  </div>
  <div class="loginButtons">
    <button type="submit" mat-button color="primary" [disabled]="form.invalid">
      <mat-icon>check_circle</mat-icon>
      Login
    </button>
  </div>
  <div *ngIf="forgot" class="tip">
    <a mat-button (click)="requestReset()">Forgot password?</a>
  </div>
</form>
