import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { UserFilter } from '../../services/auth-jwt-account.service';
import { UsersState, UsersStore } from './users.store';

@Injectable({ providedIn: 'root' })
export class UsersQuery extends QueryEntity<UsersState> {
  constructor(protected _store: UsersStore) {
    super(_store);
  }

  public selectFilter(): Observable<UserFilter> {
    return this.select((state: UsersState) => state.filter);
  }
}
