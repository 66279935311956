<form [formGroup]="form!" (submit)="save()">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>picture_in_picture</mat-icon>
      </div>
      <mat-card-title>Token Text Part</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div>
        <!-- citation -->
        <mat-form-field>
          <input matInput formControlName="citation" placeholder="citation" />
        </mat-form-field>
        &nbsp;

        <!-- transforms -->
        <mat-form-field style="width: 8em">
          <mat-select [formControl]="transform" placeholder="operation">
            <mat-option value="ws">normalize ws</mat-option>
            <mat-option value="split">split at stops</mat-option>
          </mat-select>
        </mat-form-field>
        <button
          type="button"
          mat-icon-button
          color="warn"
          matTooltip="Apply the selected text transformation"
          (click)="applyTransform()"
        >
          <mat-icon>find_replace</mat-icon>
        </button>
      </div>
      <!-- text -->
      <div>
        <ngx-monaco-editor
          [options]="editorOptions"
          formControlName="text"
        ></ngx-monaco-editor>
        <mat-error
          *ngIf="text.hasError('required') && (text.touched || text.dirty)"
          >please enter some text</mat-error
        >
      </div>

      <mat-card-actions>
        <cadmus-close-save-buttons
          [form]="form"
          [noSave]="userLevel < 2"
          (closeRequest)="close()"
        ></cadmus-close-save-buttons>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
</form>
