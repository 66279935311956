import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PasswordValidators {
  /** "Standard" password validator for my API services. */
  public static standard(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (control.value.length < 8) {
        return {
          passwordTooShort: true,
        };
      }

      if (!/.*[A-Z].*/.test(control.value)) {
        return {
          noUpperInPassword: true,
        };
      }

      if (!/.*[a-z].*/.test(control.value)) {
        return {
          noLowerInPassword: true,
        };
      }

      if (!/.*[A-Z].*/.test(control.value)) {
        return {
          noUpperInPassword: true,
        };
      }

      if (!/.*[-`~!@#$%^&*()_+=\[\]{};:'",.<>/?|\\].*/.test(control.value)) {
        return {
          noSymbolInPassword: true,
        };
      }

      return null;
    };
  }
}
