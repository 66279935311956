<header>
  <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="start center">
    <span style="flex: 0 0 60px"
      ><img src="./assets/img/logo-white-40.png" alt="Fusisoft"
    /></span>
    <a mat-button routerLink="/home">Cadmus Sidon</a>

    <button
      mat-button
      [matMenuTriggerFor]="itemMenu"
      *ngIf="logged && itemBrowsers"
    >
      Items
    </button>
    <mat-menu #itemMenu>
      <a mat-menu-item routerLink="/items">Items</a>
      <a
        mat-menu-item
        *ngFor="let entry of itemBrowsers"
        [routerLink]="'item-browser/' + getItemBrowserRoute(entry.id)"
        >{{ entry.value }}</a
      >
    </mat-menu>
    <ng-container *ngIf="logged && !itemBrowsers">
      <a mat-button routerLink="/items">Items</a>
    </ng-container>

    <a mat-button routerLink="/search" *ngIf="logged">Search</a>

    <a
      mat-button
      routerLink="/thesauri"
      *ngIf="
        user && (user.roles.includes('admin') || user.roles.includes('editor'))
      "
      >Thesauri</a
    >

    <span class="tb-fill-remaining-space"></span>

    <div *ngIf="logged" fxLayout="row" fxLayoutAlign="start center">
      <img [src]="getGravatarUrl(user!.email, 32)" [alt]="user!.userName" />
      <mat-icon
        class="small-icon"
        *ngIf="user && user.roles.includes('admin')"
        title="admin"
        >build</mat-icon
      >
      <mat-icon
        class="small-icon"
        *ngIf="user && !user.emailConfirmed"
        title="You must verify your email address! Please check your mailbox {{
          user.email
        }}"
        >feedback</mat-icon
      >

      <!-- admin menu -->
      <button
        *ngIf="user && user.roles.includes('admin')"
        mat-button
        [matMenuTriggerFor]="adminMenu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #adminMenu>
        <a mat-menu-item routerLink="/manage-users">Manage users</a>
        <a mat-menu-item routerLink="/register-user">Register user</a>
      </mat-menu>
    </div>

    <div *ngIf="!logged">
      <a mat-button routerLink="/login">Login</a>
    </div>
    <div *ngIf="logged">
      <a mat-button (click)="logout()">Logout</a>
    </div>
  </mat-toolbar>
</header>

<main>
  <router-outlet></router-outlet>
</main>

<footer>
  <div layout="row" layout-align="center center">
    <p>
      Cadmus Sidon by
      <a href="https://www.fusisoft.net" target="_blank">Daniele Fusi</a> at
      <a href="https://www.unive.it/pag/39287" target="_blank">VeDPH</a> -
      version {{ version }}
    </p>
  </div>
</footer>
