<ng-container *ngIf="form">
  <button
    type="button"
    mat-raised-button
    color="warn"
    matTooltip="Close this editor"
    (click)="close()"
  >
    <mat-icon>cancel</mat-icon>
    close
  </button>
  <button
    *ngIf="!noSave"
    type="submit"
    [disabled]="form.invalid"
    mat-raised-button
    color="primary"
    matTooltip="Save data"
  >
    <mat-icon>check_circle</mat-icon>
    save
  </button>
</ng-container>
