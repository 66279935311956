<form [formGroup]="form!" (submit)="save()">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>picture_in_picture</mat-icon>
      </div>
      <mat-card-title
        [matBadge]="categories?.value?.length"
        matBadgeOverlap="false"
        >Categories Part</mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <!-- available categories (from thesaurus) -->
      <div>
        <h3>Available Categories</h3>
        <cadmus-thesaurus-tree
          [entries]="(entries$ | async) || undefined"
          rootLabel="categories"
          [renderLabel]="renderLabel"
          (entryChange)="onEntryChange($event)"
        ></cadmus-thesaurus-tree>
      </div>

      <!-- categories -->
      <div *ngIf="categories.valid">
        <h3>Assigned Categories</h3>
        <table>
          <tbody>
            <tr *ngFor="let entry of categories.value; let i = index">
              <td>
                <button
                  type="button"
                  mat-icon-button
                  color="warn"
                  type="button"
                  matTooltip="Remove this category"
                  (click)="removeCategory(i)"
                >
                  <mat-icon>remove_circle</mat-icon>
                </button>
              </td>
              <td [matTooltip]="entry.id">{{ entry.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <cadmus-close-save-buttons
        [form]="form"
        [noSave]="userLevel < 2"
        (closeRequest)="close()"
      ></cadmus-close-save-buttons>
    </mat-card-actions>
  </mat-card>
</form>
