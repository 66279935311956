<form [formGroup]="form">
  <div formArrayName="refsArr">
    <!-- add -->
    <div>
      <button
        type="button"
        mat-stroked-button
        color="primary"
        (click)="addReference()"
        matTooltip="Add a new reference"
      >
        <mat-icon>add_circle</mat-icon> add
      </button>
    </div>
    <!-- list -->
    <div
      *ngFor="
        let item of refsArr.controls;
        let i = index;
        let first = first;
        let last = last
      "
    >
      <!-- child form -->
      <div [formGroupName]="i" class="row">
        <!-- child actions -->
        <div style="flex: 0 0 auto">
          {{ i + 1 }}.
          <button
            mat-icon-button
            type="button"
            matTooltip="Remove this reference"
            color="warn"
            (click)="removeReference(i)"
          >
            <mat-icon>remove_circle</mat-icon>
          </button>
          <button
            [disabled]="first"
            mat-icon-button
            type="button"
            matTooltip="Move reference up"
            (click)="moveReferenceUp(i)"
          >
            <mat-icon>arrow_upward</mat-icon>
          </button>
          <button
            [disabled]="last"
            mat-icon-button
            type="button"
            matTooltip="Move reference down"
            (click)="moveReferenceDown(i)"
          >
            <mat-icon>arrow_downward</mat-icon>
          </button>
        </div>

        <!-- child controls -->
        <div style="flex: 0 0 auto">
          <!-- type (bound) -->
          <ng-container *ngIf="typeEntries">
            <mat-form-field style="width: 8em">
              <mat-select formControlName="type" placeholder="type">
                <mat-option *ngFor="let e of typeEntries" [value]="e.id">
                  {{ e.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <!-- type (free) -->
          <ng-container *ngIf="!typeEntries">
            <mat-form-field style="width: 8em">
              <input matInput formControlName="type" placeholder="type" />
              <mat-error
                *ngIf="
                  $any(item)['controls'].type.hasError('max-length') &&
                  ($any(item)['controls'].type.dirty ||
                    $any(item)['controls'].type.touched)
                "
                >type too long
              </mat-error>
            </mat-form-field>
          </ng-container>
        </div>

        <div style="flex: 0 0 auto">
          <!-- tag (bound) -->
          <ng-container *ngIf="tagEntries">
            <mat-form-field style="width: 8em">
              <mat-select formControlName="tag" placeholder="tag">
                <mat-option *ngFor="let e of tagEntries" [value]="e.id">
                  {{ e.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <!-- tag (free) -->
          <ng-container *ngIf="!tagEntries">
            <mat-form-field style="width: 8em">
              <input matInput formControlName="tag" placeholder="tag" />
              <mat-error
                *ngIf="
                  $any(item)['controls'].tag.hasError('max-length') &&
                  ($any(item)['controls'].tag.dirty ||
                    $any(item)['controls'].tag.touched)
                "
                >tag too long
              </mat-error>
            </mat-form-field>
          </ng-container>
        </div>

        <!-- citation -->
        <mat-form-field style="flex: 1 0 1fr">
          <input matInput formControlName="citation" placeholder="citation" />
          <mat-error
            *ngIf="
              $any(item)['controls'].citation.hasError('max-length') &&
              ($any(item)['controls'].citation.dirty ||
                $any(item)['controls'].citation.touched)
            "
            >citation too long
          </mat-error>
        </mat-form-field>

        <!-- note -->
        <mat-form-field style="flex: 1 0 1fr">
          <input matInput formControlName="note" placeholder="note" />
          <mat-error
            *ngIf="
              $any(item)['controls'].note.hasError('max-length') &&
              ($any(item)['controls'].note.dirty ||
                $any(item)['controls'].note.touched)
            "
            >note too long
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
