<form [formGroup]="form!" (submit)="save()">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>picture_in_picture</mat-icon>
      </div>
      <mat-card-title>Metadata Part</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div formArrayName="metadata">
        <div>
          <button
            type="button"
            mat-icon-button
            color="primary"
            (click)="addMetadatum()"
          >
            <mat-icon>add_circle</mat-icon>
            add metadatum
          </button>
        </div>
        <div
          *ngFor="
            let item of metadata.controls;
            let i = index;
            let first = first;
            let last = last
          "
        >
          <!-- child form -->
          <div [formGroupName]="i" style="display: flex; gap: 8px">
            <!-- child actions -->
            <span style="flex: 0 1 auto">
              {{ i + 1 }}.
              <button
                mat-icon-button
                type="button"
                matTooltip="Remove this metadatum"
                color="warn"
                (click)="removeMetadatum(i)"
              >
                <mat-icon>remove_circle</mat-icon>
              </button>
              <button
                [disabled]="first"
                mat-icon-button
                type="button"
                matTooltip="Move metadatum up"
                (click)="moveMetadatumUp(i)"
              >
                <mat-icon>arrow_upward</mat-icon>
              </button>
              <button
                [disabled]="last"
                mat-icon-button
                type="button"
                matTooltip="Move metadatum down"
                (click)="moveMetadatumDown(i)"
              >
                <mat-icon>arrow_downward</mat-icon>
              </button>
            </span>

            <!-- child controls -->
            <!-- type (bound) -->
            <mat-form-field *ngIf="typeEntries?.length" style="flex: 1 0 auto">
              <mat-select formControlName="type" placeholder="type">
                <mat-option *ngFor="let e of typeEntries" [value]="e.id">{{
                  e.value
                }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  $any(item)['controls'].type.errors?.required &&
                  ($any(item)['controls'].type.dirty ||
                    $any(item)['controls'].type.touched)
                "
                >type required</mat-error
              >
            </mat-form-field>
            <!-- type (free) -->
            <mat-form-field *ngIf="!typeEntries?.length" style="flex: 1 0 auto">
              <input matInput formControlName="type" placeholder="type" />
              <mat-error
                *ngIf="
                  $any(item)['controls'].type.errors?.required &&
                  ($any(item)['controls'].type.dirty ||
                    $any(item)['controls'].type.touched)
                "
                >type required</mat-error
              >
              <mat-error
                *ngIf="
                  $any(item)['controls'].type.errors?.maxLength &&
                  ($any(item)['controls'].type.dirty ||
                    $any(item)['controls'].type.touched)
                "
                >type too long</mat-error
              >
            </mat-form-field>

            <!-- name -->
            <mat-form-field style="flex: 3 0 auto">
              <input matInput formControlName="name" placeholder="name" />
              <mat-error
                *ngIf="
                  $any(item)['controls'].name.errors?.required &&
                  ($any(item)['controls'].name.dirty ||
                    $any(item)['controls'].name.touched)
                "
                >name required</mat-error
              >
              <mat-error
                *ngIf="
                  $any(item)['controls'].name.errors?.maxLength &&
                  ($any(item)['controls'].name.dirty ||
                    $any(item)['controls'].name.touched)
                "
                >name too long</mat-error
              >
            </mat-form-field>

            <!-- value -->
            <mat-form-field style="flex: 3 0 auto">
              <input matInput formControlName="value" placeholder="value" />
              <mat-error
                *ngIf="
                  $any(item)['controls'].value.errors?.required &&
                  ($any(item)['controls'].value.dirty ||
                    $any(item)['controls'].value.touched)
                "
                >value required</mat-error
              >
              <mat-error
                *ngIf="
                  $any(item)['controls'].value.errors?.maxLength &&
                  ($any(item)['controls'].value.dirty ||
                    $any(item)['controls'].value.touched)
                "
                >value too long</mat-error
              >
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <cadmus-close-save-buttons
        [form]="form"
        [noSave]="userLevel < 2"
        (closeRequest)="close()"
      ></cadmus-close-save-buttons>
    </mat-card-actions>
  </mat-card>
</form>
