<form [formGroup]="form" (submit)="apply()" ng-disabled="disabled">
  <mat-form-field>
    <input matInput [formControl]="name" placeholder="name or ID" />
    <button
      mat-icon-button
      matSuffix
      type="button"
      (click)="reset()"
      color="warn"
      matTooltip="Reset filters"
      [disabled]="disabled"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>

  <button
    type="submit"
    mat-icon-button
    color="primary"
    [disabled]="disabled"
    matTooltip="Apply filters"
  >
    <mat-icon>check_circle</mat-icon>
  </button>
</form>
