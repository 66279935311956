import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { PaginationResponse, PaginatorPlugin } from '@datorama/akita';

import { GravatarService, User } from '@myrmidon/auth-jwt-login';
import { DataPage } from '@myrmidon/ng-tools';

import {
  AuthJwtAccountService,
  UserFilter,
} from '../../services/auth-jwt-account.service';
import { USERS_PAGINATOR } from '../state/users.paginator';
import { UsersState } from '../state/users.store';
import { UsersQuery } from '../state/users.query';
import { DialogService } from '../../services/dialog.service';
import { UsersService } from '../state/users.service';

@Component({
  selector: 'auth-jwt-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
})
export class UserListComponent implements OnDestroy {
  private _refresh$: BehaviorSubject<number>;
  private _filter$: Observable<UserFilter>;

  public pagination$: Observable<PaginationResponse<User>>;
  public pageSize: UntypedFormControl;
  public active$: Observable<User | undefined>;

  constructor(
    @Inject(USERS_PAGINATOR)
    public paginator: PaginatorPlugin<UsersState>,
    userQuery: UsersQuery,
    private _accountService: AuthJwtAccountService,
    private _usersService: UsersService,
    private _dialogService: DialogService,
    private _gravatarService: GravatarService,
    formBuilder: UntypedFormBuilder
  ) {
    this.pageSize = formBuilder.control(20);
    this._refresh$ = new BehaviorSubject(0);
    this._filter$ = userQuery.selectFilter();
    // https://netbasal.com/manage-your-entities-with-akita-like-a-boss-768732f8d4d1
    this.active$ = userQuery.selectActive<User>();

    this.pagination$ = combineLatest([
      this.paginator.pageChanges,
      this.pageSize.valueChanges.pipe(
        // we are required to emit at least the initial value
        // as combineLatest emits only if ALL observables have emitted
        startWith(20),
        // clear the cache when page size changes
        tap((_) => {
          this.paginator.clearCache();
        })
      ),
      this._filter$.pipe(
        // clear the cache when filters changed
        tap((_) => {
          this.paginator.clearCache();
        })
      ),
      this._refresh$.pipe(
        // clear the cache when forcing refresh
        tap((_) => {
          this.paginator.clearCache();
        })
      ),
    ]).pipe(
      // for each emitted value, combine into a filter and use it
      // to request the page from server
      switchMap(([pageNumber, pageSize, filter, refresh]) => {
        // const filter = { ...this._docsQuery.getValue().filter };
        const f = { ...filter };
        f.pageNumber = pageNumber;
        f.pageSize = pageSize;
        const request = this.getRequest(f);
        // update saved filters
        this.paginator.metadata.set('filter', f);
        return this.paginator.getPage(request);
      })
    );
  }

  ngOnDestroy(): void {
    this.paginator.destroy();
  }

  private getRequest(
    filter: UserFilter
  ): () => Observable<PaginationResponse<User>> {
    return () =>
      this._accountService.getUsers(filter).pipe(
        // adapt server results to the paginator plugin
        map((p: DataPage<User>) => {
          return {
            currentPage: p.pageNumber,
            perPage: p.pageSize,
            lastPage: p.pageCount,
            data: p.items,
            total: p.total,
          };
        })
      );
  }

  public pageChange(event: PageEvent): void {
    // https://material.angular.io/components/paginator/api
    this.paginator.setPage(event.pageIndex + 1);
    if (event.pageSize !== this.pageSize.value) {
      this.pageSize.setValue(event.pageSize);
    }
  }

  public refresh(): void {
    this.paginator.clearCache();
    this._refresh$.next(this._refresh$.value + 1);
  }

  public deleteUser(user: User): void {
    this._dialogService
      .confirm('Confirm', `Delete User ${user.userName}?`)
      .pipe(take(1))
      .subscribe((yes) => {
        if (!yes) {
          return;
        }
        this._usersService.deleteUser(user.userName).finally(() => {
          this.refresh();
        });
      });
  }

  public setActiveUser(user: User | null): void {
    this._usersService.setActive(user?.userName || null);
  }

  public resetActiveUser(): void {
    this._usersService.setActive(null);
  }

  public saveActiveUser(user: User): void {
    this._usersService.updateActive(user).finally(() => {
      this.refresh();
    });
  }

  public onUserEditorClose(): void {
    this.setActiveUser(null);
  }

  public getGravatarUrl(email: string, size = 80): string | null {
    return this._gravatarService.buildGravatarUrl(email, size);
  }
}
