<form [formGroup]="form!" (submit)="save()">
  <mat-card>
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>textsms</mat-icon>
      </div>
      <mat-card-title
        >Orthography Fragment {{ model?.location }}</mat-card-title
      >
      <mat-card-subtitle>
        {{ model?.baseText }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-form-field>
          <input
            type="text"
            matInput
            formControlName="standard"
            placeholder="standard"
            autofocus
          />
          <mat-error
            *ngIf="
              standard.hasError('required') &&
              (standard.touched || standard.dirty)
            "
            >please enter the standard orthography</mat-error
          >
          <mat-error
            *ngIf="
              standard.hasError('maxLength') &&
              (standard.touched || standard.dirty)
            "
            >too long</mat-error
          >
        </mat-form-field>
        <span *ngIf="model?.baseText" class="base-text">{{
          model!.baseText || ''
        }}</span>
        <mat-toolbar>
          <button
            type="button"
            color="primary"
            mat-icon-button
            matTooltip="Add a new operation"
            (click)="addOperation()"
          >
            <mat-icon>add_circle</mat-icon>
          </button>
          <button
            type="button"
            mat-icon-button
            matTooltip="Automatically set operations from differences"
            [disabled]="!standard.value || !model?.baseText"
            (click)="autoAddOperations()"
          >
            <mat-icon>queue</mat-icon>
          </button>
          <button
            type="button"
            color="warn"
            mat-icon-button
            matTooltip="Delete all the operations"
            [disabled]="operations?.length === 0"
            (click)="clearOperations()"
          >
            <mat-icon>delete_sweep</mat-icon>
          </button>
        </mat-toolbar>
        <div formArrayName="operations">
          <ol
            *ngFor="
              let item of operations.controls;
              let i = index;
              let first = first;
              let last = last
            "
          >
            <!-- child form -->
            <li [formGroupName]="i">
              <!-- child controls -->
              <mat-form-field>
                <input
                  matInput
                  formControlName="text"
                  placeholder="operation"
                  autofocus
                />
              </mat-form-field>
              <!-- child actions -->
              <button
                mat-icon-button
                type="button"
                matTooltip="Edit this operation"
                color="primary"
                (click)="editOperation(i)"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-icon-button
                type="button"
                matTooltip="Delete this operation"
                color="warn"
                (click)="deleteOperation(i)"
              >
                <mat-icon>remove_circle</mat-icon>
              </button>
              <button
                [disabled]="first"
                mat-icon-button
                type="button"
                matTooltip="Move this operation up"
                (click)="moveOperationUp(i)"
              >
                <mat-icon>arrow_upward</mat-icon>
              </button>
              <button
                [disabled]="last"
                mat-icon-button
                type="button"
                matTooltip="Move this operation down"
                (click)="moveOperationDown(i)"
              >
                <mat-icon>arrow_downward</mat-icon>
              </button>
            </li>
          </ol>
        </div>
      </div>
      <div *ngIf="currentOperation" [@slideInOut]>
        <cadmus-msp-operation
          [operation]="currentOperation"
          (operationChange)="currentOperationSaved($event)"
          (operationClose)="currentOperationClosed()"
        ></cadmus-msp-operation>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <cadmus-close-save-buttons
        [form]="form"
        [noSave]="userLevel < 2"
        (closeRequest)="close()"
      ></cadmus-close-save-buttons>
    </mat-card-actions>
  </mat-card>
</form>
