import { StoreConfig, EntityStore, EntityState, ActiveState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { User } from '@myrmidon/auth-jwt-login';
import { UserFilter } from '../../services/auth-jwt-account.service';

// https://netbasal.gitbook.io/akita/entity-store/entity-store/active-state
export interface UsersState
  extends EntityState<User, string>,
    ActiveState<string> {
  filter: UserFilter;
  active: string | null;
}

const initialState: UsersState = {
  filter: {
    pageNumber: 1,
    pageSize: 20,
  },
  active: null
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Users', idKey: 'userName', resettable: true })
export class UsersStore extends EntityStore<UsersState> {
  constructor() {
    super(initialState);
  }
}
