import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { DataPage, EnvService, ErrorService } from '@myrmidon/ng-tools';
import { User } from '@myrmidon/auth-jwt-login';

/**
 * User registration model. You can expand this interface to add more data.
 */
export interface RegistrationModel {
  email: string;
  name: string;
  firstName?: string;
  lastName?: string;
  password: string;
}

/**
 * Result of an existing resource check.
 */
export interface ExistResult {
  entry: string;
  isExisting: boolean;
}

/**
 * Password change data.
 */
export interface PasswordChange {
  email: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

/**
 * User filter parameters.
 */
export interface UserFilter {
  pageNumber: number;
  pageSize: number;
  name?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthJwtAccountService {
  constructor(
    private _http: HttpClient,
    private _error: ErrorService,
    private _env: EnvService
  ) {}

  /**
   * Checks if the specified email address is registered on the server.
   * @param email email address to test.
   * @returns result.
   */
  public isEmailRegistered(email: string): Observable<ExistResult> {
    return this._http.get<ExistResult>(
      this._env.get('apiUrl') +
        'accounts/emailexists/' +
        encodeURIComponent(email)
    );
  }

  /**
   * Checks if the specified user's given name is registered on the server.
   * This name is a nickname chosen by users during registration, and is the key
   * used for referencing users when talking to the server.
   * @param name name to test.
   * @returns result.
   */
  public isNameRegistered(name: string): Observable<ExistResult> {
    return this._http.get<ExistResult>(
      this._env.get('apiUrl') +
        'accounts/nameexists/' +
        encodeURIComponent(name)
    );
  }

  /**
   * Register the user with the specified registration data.
   * @param registration The registration data.
   */
  public register(registration: RegistrationModel): Observable<any> {
    // const options = {
    //   headers: this.createAuthHeaders({
    //     'Content-Type': 'application/json',
    //   }),
    // };
    return this._http.post(
      this._env.get('apiUrl') + 'accounts/register',
      registration
      // options
    );
  }

  /**
   * Resend the confirmation email to the specified address.
   * @param email address.
   */
  public resendConfirmEmail(email: string): Observable<any> {
    // const options = {
    //   headers: this.createAuthHeaders({
    //     'Content-Type': 'application/json',
    //   }),
    // };
    return this._http.get(
      this._env.get('apiUrl') +
        'accounts/resendconfirm/' +
        encodeURIComponent(email)
      // options
    );
  }

  /**
   * Request a password reset for the specified email address.
   * @param email Email address.
   */
  public requestPasswordReset(email: string): Observable<any> {
    return this._http.post(
      this._env.get('apiUrl') + 'accounts/resetpassword/request',
      { email }
    );
  }

  public getAllUsers(): Observable<DataPage<User>> {
    return this._http
      .get<DataPage<User>>(this._env.get('apiUrl') + 'users', {
        params: new HttpParams().set('pageNumber', '1'),
      })
      .pipe(retry(3), catchError(this._error.handleError));
  }

  public getUsers(filter: UserFilter): Observable<DataPage<User>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('pageNumber', filter.pageNumber.toString());
    httpParams = httpParams.set('pageSize', filter.pageSize.toString());
    if (filter.name) {
      httpParams = httpParams.set('name', filter.name);
    }

    return this._http
      .get<DataPage<User>>(this._env.get('apiUrl') + 'users', {
        params: httpParams,
      })
      .pipe(retry(3), catchError(this._error.handleError));
  }
  /**
   * Get the top N users matching the specified name filter.
   * @param nameFilter The user name filter.
   * @param limit The maximum number of users to get.
   */
  public getTopUsers(nameFilter: string, limit = 10): Observable<User[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('pageNumber', '1');
    httpParams = httpParams.set('pageSize', limit.toString());
    if (nameFilter) {
      httpParams = httpParams.set('name', nameFilter);
    }
    const options =
      httpParams.keys().length > 0
        ? {
            params: httpParams,
          }
        : {};

    return this._http
      .get<User[]>(this._env.get('apiUrl') + 'users', options)
      .pipe(retry(3), catchError(this._error.handleError));
  }

  /**
   * Get information about all the users listed in the specified names.
   * @param names User(s) names.
   */
  public getUsersFromNames(names: string[]): Observable<User[]> {
    let httpParams = new HttpParams();
    if (names && names.length > 0) {
      httpParams = httpParams.set('names', names.join(','));
    }
    const options =
      httpParams.keys().length > 0
        ? {
            params: httpParams,
          }
        : {};

    return this._http
      .get<User[]>(this._env.get('apiUrl') + 'users-from-names', options)
      .pipe(retry(3), catchError(this._error.handleError));
  }

  /**
   * Get data about the specified user.
   * @param name The user name.
   */
  public getUser(name: string): Observable<User> {
    return this._http
      .get<User>(this._env.get('apiUrl') + 'users/' + name)
      .pipe(retry(3), catchError(this._error.handleError));
  }

  /**
   * Update the editable data for the specified user.
   * @param user The user to update.
   */
  public updateUser(user: User): Observable<any> {
    return this._http
      .put(this._env.get('apiUrl') + 'users', user)
      .pipe(catchError(this._error.handleError));
  }

  /**
   * Request a password reset email for the specified email address.
   * @param email The email address to receive the reset message.
   */
  public resetPassword(email: string): Observable<any> {
    return this._http
      .post(this._env.get('apiUrl') + 'accounts/resetpassword/request', {
        email: email,
      })
      .pipe(catchError(this._error.handleError));
  }

  /**
   * Change the password.
   * @param change The password change data.
   */
  public changePassword(change: PasswordChange): Observable<any> {
    return this._http
      .post(this._env.get('apiUrl') + 'accounts/changepassword', change)
      .pipe(catchError(this._error.handleError));
  }

  /**
   * Delete the user with the specified username.
   * @param name The user name.
   */
  public deleteUser(name: string): Observable<any> {
    return this._http
      .delete(this._env.get('apiUrl') + 'accounts/' + name)
      .pipe(catchError(this._error.handleError));
  }
}
