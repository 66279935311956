import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  AuthJwtAccountService,
  ExistResult,
  RegistrationModel,
} from '../../services/auth-jwt-account.service';

import { PasswordValidators } from '../../validators/password.validators';

@Component({
  selector: 'auth-jwt-registration',
  templateUrl: './auth-jwt-registration.component.html',
  styleUrls: ['./auth-jwt-registration.component.css'],
})
export class AuthJwtRegistrationComponent implements OnInit {
  public busy: boolean | undefined;
  // form
  public registration: UntypedFormGroup;
  public email: UntypedFormControl;
  public name: UntypedFormControl;
  public firstName: UntypedFormControl;
  public lastName: UntypedFormControl;
  public passwords: UntypedFormGroup;
  public password: UntypedFormControl;
  public confirmPassword: UntypedFormControl;

  /**
   * Emitted when a user was successfully registered. Usually
   * you should handle this to move away from the registration
   * page.
   */
  @Output()
  public registered: EventEmitter<any>;

  constructor(
    formBuilder: UntypedFormBuilder,
    private _snackbar: MatSnackBar,
    private _accountService: AuthJwtAccountService
  ) {
    this.registered = new EventEmitter<any>();
    // form
    this.email = formBuilder.control(
      '',
      [Validators.required, Validators.email],
      this.getUniqueEmailValidator(this._accountService).bind(this)
    );

    this.name = formBuilder.control(
      '',
      Validators.required,
      this.getUniqueNameValidator(this._accountService).bind(this)
    );

    this.firstName = formBuilder.control('', [
      Validators.required,
      Validators.maxLength(50),
    ]);
    this.lastName = formBuilder.control('', [
      Validators.required,
      Validators.maxLength(50),
    ]);

    // http://stackoverflow.com/questions/35474991/angular-2-form-validating-for-repeat-password
    this.password = formBuilder.control(
      '',
      Validators.compose([Validators.required, PasswordValidators.standard])
    );
    this.confirmPassword = formBuilder.control('', Validators.required);
    this.passwords = formBuilder.group(
      {
        password: this.password,
        confirmPassword: this.confirmPassword,
      },
      { validator: this.areEqual.bind(this) }
    );

    this.registration = formBuilder.group({
      email: this.email,
      name: this.name,
      firstName: this.firstName,
      lastName: this.lastName,
      passwords: this.passwords,
    });
  }

  private areEqual(group: UntypedFormGroup): { [key: string]: boolean } | null {
    if (this.password.value === this.confirmPassword.value) {
      return null;
    }
    return {
      areEqual: true,
    };
  }

  /**
   * Creates a unique name validator. There is no dependency injection at this level,
   * but we can use closures. As a matter of fact, we have access to the service instance
   * from the component where we register validators. So we will implement a function
   * that will accept the service as parameter, and create the actual validation function.
   * This function will have access to the service when called during the validation process.
   * See http://restlet.com/blog/2016/02/17/implementing-angular2-forms-beyond-basics-part-2/.
   */
  private getUniqueNameValidator(service: AuthJwtAccountService) {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        // avoid checking if empty
        if (!control.value) {
          resolve(null);
        } else {
          service.isNameRegistered(control.value).subscribe(
            (data: ExistResult) => {
              if (!data.isExisting) {
                resolve(null);
              } else {
                resolve({ uniqueName: true });
              }
            },
            (err) => {
              resolve({ uniqueName: true });
            }
          );
        }
      });
    };
  }

  private getUniqueEmailValidator(service: AuthJwtAccountService) {
    return (
      control: AbstractControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      return new Promise((resolve, reject) => {
        // avoid checking if empty
        if (!control.value) {
          resolve(null);
        } else {
          service.isEmailRegistered(control.value).subscribe(
            (data: ExistResult) => {
              if (!data.isExisting) {
                resolve(null);
              } else {
                resolve({ uniqueEmail: true });
              }
            },
            (err) => {
              resolve({ uniqueEmail: true });
            }
          );
        }
      });
    };
  }

  ngOnInit(): void {}

  public getEmailErrorLabel(): string | null {
    if (!this.email.dirty) {
      return null;
    }

    if (this.email.hasError('required')) {
      return 'email address required';
    }
    if (this.email.hasError('email')) {
      return 'invalid email address';
    }
    if (this.email.hasError('uniqueEmail') && !this.email.pending) {
      return 'email address already registered';
    }
    return null;
  }

  public getNameErrorLabel(): string | null {
    if (!this.name.dirty) {
      return null;
    }

    if (this.name.hasError('required')) {
      return 'username required';
    }

    if (this.name.hasError('pattern')) {
      return 'invalid username';
    }

    if (this.name.hasError('uniqueName') && !this.name.pending) {
      return 'username already taken';
    }

    return null;
  }

  public getPasswordErrorLabel(): string | null {
    if (!this.password.dirty) {
      return null;
    }

    if (this.password.hasError('required')) {
      return 'password required';
    }
    if (this.password.hasError('passwordTooShort')) {
      let s = 'at least 8 characters';
      if (this.password.value) {
        s += ` (now ${this.password.value.length})`;
      }
      return s;
    }
    if (this.password.hasError('noUpperInPassword')) {
      return 'at least 1 uppercase letter';
    }
    if (this.password.hasError('noLowerInPassword')) {
      return 'at least 1 lowercase letter';
    }
    if (this.password.hasError('noSymbolInPassword')) {
      return 'at least 1 punctuation or symbol';
    }

    return null;
  }

  public onSubmit(): void {
    if (
      !this.registration.valid ||
      this.busy ||
      this.name.pending ||
      this.email.pending
    ) {
      return;
    }

    const model: RegistrationModel = {
      email: this.email.value,
      name: this.name.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      password: this.password.value,
    };

    this.busy = true;
    this._accountService
      .register(model)
      .pipe(take(1))
      .subscribe(
        () => {
          this.busy = false;
          this._snackbar.open('Registration succeeded', 'OK');
          // this.registration.reset();
          // this.registration.clearValidators();
          // this.registration.markAsPristine();
          this.registered.emit();
        },
        (error) => {
          this.busy = false;
          console.error(error);
          this._snackbar.open('Registration error', 'OK');
        }
      );
  }
}
