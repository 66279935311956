<div style="margin: 0 16px">
  <div class="banner">
    <img
      class="banner-img"
      srcset="
        ./assets/img/banner-1024.jpg 1024w,
        ./assets/img/banner-512.jpg   512w
      "
      sizes="(max-width: 600px) 512px, 1024px"
    />
  </div>
  <article>
    <h2>Cadmus Sidon</h2>
    <h3>Sidonius Apollinaris Letters Cadmus Editor</h3>
    <p *ngIf="!logged" style="margin-bottom: 8px">
      <a mat-mini-fab color="primary" routerLink="/login"
        ><mat-icon>login</mat-icon></a
      ><span style="margin-left: 8px">please login</span>
    </p>
  </article>
</div>
